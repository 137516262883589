import { createRouter, createWebHistory } from 'vue-router'
import store from '../store/index'
let viewType = 'web'
let memberDefaultUrl = 'AccountOverviewView'
if (navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)) {
  viewType = 'mobile'
  memberDefaultUrl = 'IndexView'
}

const routes = [
  {
    path: '/',
    name: 'base',
    component: () => import(`../views/${viewType}/PageBasic.vue`),
    children: [
      {
        // 首頁
        path: '/',
        name: 'home',
        component: () => import(`../views/${viewType}/Index.vue`)
      },
      {
        // 商品列表
        path: '/product',
        name: 'product',
        query: { parent_categry_id: '' },
        component: () => import(`../views/${viewType}/ProductView.vue`)
      },
      {
        // 商品詳情
        path: '/product/details',
        name: 'productDetails',
        component: () => import(`../views/${viewType}/ProductDetailsView.vue`)
      },
      {
        // 購物車
        path: '/cart',
        name: 'cart',
        component: () => import(`../views/${viewType}/CartView.vue`)
      },
      // {
      //   // 結帳頁
      //   path: '/checkout',
      //   name: 'checkout',
      //   component: () => import(`../views/${viewType}/CheckoutView.vue`)
      // },
      {
        // 訂單完成
        path: '/orderfinish',
        name: 'orderfinish',
        component: () => import(`../views/${viewType}/OrderFinishView.vue`)
      },
      {
        // 經銷商快速下單
        path: '/quickorder',
        name: 'quickorder',
        component: () => import(`../views/${viewType}/QuickOrderView.vue`)
      },
      {
        // 最新消息
        path: '/news',
        name: 'news',
        component: () => import(`../views/${viewType}/NewsView.vue`)
      },
      {
        // 最新消息詳情
        path: '/news/details',
        name: 'newsDetails',
        component: () => import(`../views/${viewType}/NewsDetailsView.vue`)
      },
      {
        // 活動公告
        path: '/activity',
        name: 'activity',
        component: () => import(`../views/${viewType}/ActivityView.vue`)

      },
      {
        // 活動公告詳情
        path: '/activity/details',
        name: 'activitydetails',
        component: () => import(`../views/${viewType}/ActivityDetailsView.vue`)

      },
      {
        // 聯絡我們
        path: '/contactus',
        name: 'contactus',
        component: () => import(`../views/${viewType}/ContactUsView.vue`)

      },
      {
        // 隱私條款
        path: '/terms',
        name: 'terms',
        component: () => import(`../views/${viewType}/TermsView.vue`)

      },
      {
        // 陌生客查詢訂單
        path: '/stranger',
        name: 'strangerinquiry',
        component: () => import(`../views/${viewType}/StrangerView.vue`)

      },
      {
        // 經銷商註冊
        path: '/register',
        name: 'register',
        component: () => import(`../views/${viewType}/RegisterView.vue`)
      },
      {
        // 經銷商登入
        path: '/login',
        name: 'login',
        component: () => import(`../views/${viewType}/LoginView.vue`)
      },
      {
        // 忘記密碼
        path: '/password',
        name: 'password',
        component: () => import(`../views/${viewType}/PasswordView.vue`)
      },
      {
        // 手機版index
        path: '/phome',
        name: 'phome',
        component: () => import(`../views/${viewType}/PhomeView.vue`)
      },
      {
        path: '/index',
        name: 'index',
        component: () => import(`../views/${viewType}/Index.vue`)
      },
      {
        path: '/test',
        name: 'test',
        component: () => import(`../views/test/TeatBank.vue`)
      },
      getMember()
    ]
  }
]

function getMember () {
  // 預設名稱
  const memberObject = {
    path: `/member`,
    name: `member_base`,
    component: () => import(`../views/${viewType}/member/MemberBasic.vue`),
    children: [
      {
        // 會員中心
        path: `/member/account_overview`,
        name: `member_account_overview`,
        component: () => import(`../views/${viewType}/member/AccountOverviewView.vue`)
      },
      {
        // 會員中心
        path: `/member`,
        name: `member_account`,
        component: () => import(`../views/${viewType}/member/${memberDefaultUrl}.vue`)
        // component: () => import(`../views/${viewType}/member/AccountView.vue`)
      },
      {
        // 經銷商
        path: `/member/dealer_account`,
        name: `member_dealer_account`,
        component: () => import(`../views/${viewType}/member/DealerAccountView.vue`)
      },
      {
        // 常用地址
        path: `/member/address`,
        name: `member_address`,
        component: () => import(`../views/${viewType}/member/AddressView.vue`)
      },
      {
        //  通知總覽
        path: `/member/notice`,
        name: `member_notice`,
        component: () => import(`../views/${viewType}/member/NoticeView.vue`)
      },
      {
        // 購買清單
        path: `/member/buy_list`,
        name: `member_buy_list`,
        component: () => import(`../views/${viewType}/member/BuyListView.vue`)
      },
      {
        // 我的抽獎券
        path: `/member/my_ticket`,
        name: `member_my_ticket`,
        component: () => import(`../views/${viewType}/member/MyTicketView.vue`)
      },
      {
        // 我的抽獎券
        path: `/member/my_coupon`,
        name: `member_my_ticket`,
        component: () => import(`../views/${viewType}/member/MyCoupon.vue`)
      },
      {
        //
        path: `/member/favorite`,
        name: `member_favorite`,
        component: () => import(`../views/${viewType}/member/FavoriteView.vue`)
      },
      {
        //
        path: `/member/point`,
        name: `member_point`,
        component: () => import(`../views/${viewType}/member/PointView.vue`)
      },
      {
        //
        path: `/member/pointall`,
        name: `member_pointall`,
        component: () => import(`../views/${viewType}/member/PointALLView.vue`)
      },
      {
        // 推薦圖
        path: `/member/refer`,
        name: `member_refer`,
        component: () => import(`../views/${viewType}/member/ReferView.vue`)
      },
      {
        // 推薦列表
        path: `/member/refer_list`,
        name: `member_refer_list`,
        component: () => import(`../views/${viewType}/member/ReferListView.vue`)
      },
      {
        // 月獎金
        path: `/member/month_point`,
        name: `member_month_point`,
        component: () => import(`../views/${viewType}/member/MonthPointView.vue`)
      },
      {
        // 賬號綁定
        path: `/member/account_binding`,
        name: `member_account_binding`,
        component: () => import(`../views/${viewType}/member/AccountBindingView.vue`)
      },
      {
        // 訂單詳情
        path: `/member/order_detail`,
        name: `member_order_detail`,
        component: () => import(`../views/${viewType}/member/OrderDetailView.vue`)
      },
      {
        // 配送進度
        path: `/member/delivery_progress`,
        name: `member_delivery_progress`,
        component: () => import(`../views/${viewType}/member/DeliveryProgressView.vue`)
      },
      {
        // 經銷商雲倉庫 ok
        path: `/member/depot_cloud`,
        name: `member_depot_cloud`,
        component: () => import(`../views/${viewType}/member/DepotCloudView.vue`)
      },
      {
        // 經銷商雲倉出入存紀錄 ok
        path: `/member/depot_record`,
        name: `member_depot_record`,
        component: () => import(`../views/${viewType}/member/DepotRecordView.vue`)
      },
      {
        // 經銷商雲倉庫 – 提貨 ok
        path: `/member/pick_up`,
        name: `member_pick_up`, 
        component: () => import(`../views/${viewType}/member/PickUpView.vue`)
      },
      {
        // 經銷商雲倉庫 – 提貨申請紀錄
        path: `/member/pickup_record`,
        name: `member_pickup_record`,
        component: () => import(`../views/${viewType}/member/PickUpRecordView.vue`)
      },
      {
        // 經銷商雲倉庫 – 經銷商個人倉庫 – 1 ok
        path: `/member/i_depot`,
        name: `member_i_depot`,
        component: () => import(`../views/${viewType}/member/IDepotView.vue`)
      },
      {
        // 經銷商雲倉庫 – 經銷商個人倉出入存紀錄 ok
        path: `/member/idepot_stock`,
        name: `member_idepot_stock`,
        component: () => import(`../views/${viewType}/member/IDepotStockView.vue`)
      },
      {
        // 經銷商-我是賣家 - 全部
        path: `/member/seller`,
        name: `member_seller`,
        component: () => import(`../views/${viewType}/member/SellerView.vue`)
      },
      {
        // 經銷商--經銷商-我是賣家 -訂單詳情
        path: `/member/seller_detail`,
        name: `member_seller_detail`,
        component: () => import(`../views/${viewType}/member/SellerDetailView.vue`)
      },
      {
        // 經銷商--經銷商-我是賣家 -配送資訊
        path: `/member/seller_delivery`,
        name: `member_seller-delivery`,
        component: () => import(`../views/${viewType}/member/SellerDeliveryView.vue`)
      },
      {
        // 經銷商雲倉庫 – 換貨申請紀錄
        path: `/member/exchange`,
        name: `member_exchange`,
        component: () => import(`../views/${viewType}/member/ExchangeView.vue`)
      },
      {
        //  經銷商雲倉庫 – 換貨申請
        path: `/member/ex_request`,
        name: `member_ex_request`,
        component: () => import(`../views/${viewType}/member/ExRequestView.vue`)
      },
      {
        // 會員中心
        path: `/member/account_share`,
        name: `member_account_share`,
        component: () => import(`../views/${viewType}/member/AccountShareView.vue`)
      }
    ]
  }
  return memberObject
}

const router = createRouter({
  history: createWebHistory(),
  routes
})
// 在跳頁前的動作
router.beforeEach(function (to, from, next) {
  let token = store.state.token
  if (to['name'].includes('member') == 1) {
      if (token === '') {
        next({ path: `/login` })
      } else {
        next()
      }
  } else {
    next()
  }
  setTimeout(() => {
    window.scrollTo(0, 0)
  }, 100)
})


export default router
