<template>
  <div class="load-view" id="loadingArea">
    <div class="spinner-border" style="width: 3rem; height: 3rem" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>
  <router-view />
</template>
<script>
export default {
  name: "App",
  metaInfo: {
    title: "Default App Title",
    titleTemplate: "%s | vue-meta Example App",
  },
  mounted() {
    window.onload = function () {
      try {
        const myCollapsible = document.querySelector("#cartCollapse");

        myCollapsible.addEventListener("show.bs.collapse", () => {
          const cartMask = document.getElementById("cartMask");
          cartMask.style.display = "block";
          cartMask.addEventListener("click", function () {
            cartMask.style.display = "none";
            myCollapsible.className = "collapse-horizontal collapse";
          });
        });
      } catch (e) {
        console.log(e);
      }
      window.addEventListener("scroll", () => {
        let navbarHeight = "";
        if (
          navigator.userAgent.match(
            /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
          )
        ) {
          navbarHeight = document.getElementById("navbarmobile");
        } else {
          navbarHeight = document.getElementById("navbar");
        }
        try {
          const headerNewsHeight =
            document.getElementById("headerNews").clientHeight;
          const navSpaceHeight = document.getElementById("navSpace");
          const scrollPos = window.scrollY;
          if (scrollPos >= headerNewsHeight) {
            navSpaceHeight.style.height = navbarHeight.clientHeight + "px";
            navbarHeight.className = " navbar bg-light fixed-top";
          } else {
            navSpaceHeight.style.height = "0px";
            navbarHeight.className = " navbar bg-light ";
          }
        } catch (e) {
          console.log(e);
        }
      });
      setTimeout(function(){
        document.getElementById('loadingArea').style.display = "none";
        try {
          var img = document.querySelectorAll(".ck-content img")
          for (var i = 0; i < img.length; i++) {
            if(img[i].width+30>=screen.width){
                img[i].style = "height:auto;max-width:100%"
            }
          }
        } catch (e) {
          console.log(e)
        }
        try {
          var input = document.querySelectorAll(".ck-content input")
          for (var j = 0; j < input.length; j++) {
            if(input[j].width+30>=screen.width){
                input[j].style = "height:auto;max-width:100%"
            }
          }
        } catch (e) {
          console.log(e)
        }
      }, 500);
    };
  },
  methods: {
    gotop() {
      window.scroll({
        top: 0,
        behavior: "smooth",
      });
    },
  },
};
</script>
<style lang="scss">
#app {
  font-family: "Microsoft JhengHei", serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
.load-view {
  width: 100vw;
  height: 100vh;
  display: block;
  z-index: 99999999999;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
}
</style>
